import React, { useState, useEffect } from "react";
import '../styles/styles.css';

export default function Tilesetify(props) {
  const [state, setstate] = useState(props);

  useEffect(() => {
    setstate(props);
  }, [props]);

  return (
    <div>
      <div className='textaligncenter'>
        <img className='logo' alt='' src="./img/tilesetify/logo-min.png" />
        <div className='title'>{state.page.name}</div>

        <p/>
        <table className='buttonTable'>
          <tbody>
            <tr>
              <td>
                <span id='5'>
                  <button
                    className="button buttonTilesetify"
                    type="submit"
                    onClick={() => window.open('./main/src/dl/Tilesetify_installer_en.zip')}
                  >
                    English Installer <br/>(for Windows)
                  </button>
                </span>
              </td>
              <td>
                <span id='6'>
                  <button
                    className="button buttonTilesetify"
                    type="submit"
                    onClick={() => window.open('./main/src/dl/Tilesetify_archive_en.zip')}
                  >
                    English Archive <br/>(for Windows)
                  </button>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span id='9'>
                  <button
                    className="button buttonTilesetify"
                    type="submit"
                    onClick={() => window.open('./main/src/dl/Tilesetify_installer_fr.zip')}
                  >
                    Installateur en Français <br/>(pour Windows)
                  </button>
                </span>
              </td>
              <td>
                <span id='10'>
                  <button
                    className="button buttonTilesetify"
                    type="submit"
                    onClick={() => window.open('./main/src/dl/Tilesetify_archive_fr.zip')}
                  >
                    Archive en Français <br/>(pour Windows)
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <p/>
        Create a tileset from a 2D video game map with just a few clicks.
      </div>

      <p/>

      <ul>
        <li>
        Configure the format of the tiles with the help of a grid.
        </li>
        <li>
        Scroll your map with your keyboard or the arrows on screen so that you can
        make sure that your selected format does inded match this of the map itself.
        </li>
        <li>
        Configure the tileset's width at will. Its height will be determined accordingly.
        </li>
        <li>
        Save your tileset as a .png if you wish to do so (in case the map was in
        another format and you want to keep transparency, for instance).
        </li>
      </ul>

      <p/>
      Github: <a href="https://github.com/Kamille-Ligula/tilesetify" target="_blank" rel="noreferrer">https://github.com/Kamille-Ligula/tilesetify</a>
      <p/>
      <div className='textaligncenter'>
        <span id='8'><img alt='' className='screenIMG' id='screen' src="./img/tilesetify/screen8en-min.png" /></span>
        <div className='tilesetifyCenter'><span id='7'>Example map: Aëdemphia, by Sylvanor</span> (<a href="https://www.aedemphia-rpg.net/index.php" target="_blank" rel="noreferrer">https://www.aedemphia-rpg.net/index.php</a>)</div>
      </div>
    </div>
  )
}
